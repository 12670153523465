<template>
	
	<el-dialog :title="titleWindows" :visible.sync="openWindows"
	width="950px" append-to-body>
		<div>
			<el-link  class="time_panel" :class="dateSelTime.date==item.date?'primary':''"  
				@click="getDate(item)" v-for="(item ,index) in dateList" :key="index" >
				<div    class="time_panel_week">{{item.week}}</div>
				<div   class="time_panel_date">{{item.date}}</div>
			</el-link>
		</div>
	
		<div>
			<el-table  :data="dataList">   
				<el-table-column label="时间" align="center" prop="endTime" >   
					<template slot-scope="scope">
						{{scope.row.startTime}}-{{scope.row.endTime}}
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" class-name="small-padding fixed-width">
					<template slot-scope="scope">
							<el-button size="mini" type="text" icon="el-icon-message-solid"  @click="reservation(scope.row)" >预约</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>


<script>
	import timeObj  from '@/common/time.js'
	import config from '@/common/config.js'
	
	export default {
		name: "timePanel",
		props: {
		  titleWindows:{type:String,default:'人员'},
		  doctorId:{type:String,default:""},
		  isWindows: {type: Boolean,default: false,},
		},
		watch: {
		  isWindows: {
		    handler(val) {
		      this.openWindows=val;
		    },
		    immediate: true,
		  },
		  doctorId: {
		    handler(val) {
		      this.getDateDoctor();
		    },
		    immediate: true,
		  },
		  openWindows: {
		    handler(val) {
				this.openWindows=val;
				this.$emit("openWindows",val);
		    },
		    immediate: true,
		  },
		  
		},
		data() {
			return {
				dataList:[],
				dateList:timeObj.get7Days(),
				dateSelTime:timeObj.get7Days()[0],
				loading:'false',
				openWindows:true,
			}
		},
		created() {
		 
		},
		methods: {
			reservation(item){
				this.$router.push("/addConsultment?doctorId="+
					this.doctorId+"&date="+this.dateSelTime.dateY+"&startTime="+
					item.startTime+"&endTime"+item.endTime);
				// uni.navigateTo({
					
				// 	url:"/pages/appointment/addConsultment/addConsultment?doctorId="+
				// 	this.doctorId+"&date="+this.dateSelTime.dateY+"&startTime="+
				// 	item.startTime+"&endTime"+item.endTime
				// });
				
					this.openWindows=false;
			},
			getDate(date){
				this.dateSelTime=date;
				this.getDateDoctor();
			},
			
			getDateDoctor(){
				var  _this1=this;
					_this1.dataList=[];
				config.ajaxAw("/kongtangweishi/api/doctorOnlineTime/getDoctorTimeList",
				{
					doctorDistrictId:config.doctorDistrictId,
					
					page:1, 
					date: this.dateSelTime.dateY,
					userId: this.doctorId
				},function(data){
					setTimeout(function(){
						_this1.dataList=data;
						_this1.loading = false;
					},1000)
					
				});
				
			}
			
		}
	}
	
</script>


<style>
	
	.time_panel{margin: 10px 20px;text-align: center}
	.time_panel_week{font-size: 12px;color: #999;}
	.time_panel_date{font-size: 16px;}
	
	.time_panel.primary{color: #409EFF;}
	.time_panel.primary .time_panel_week{color: #409EFF;}
	
	
</style>