<template>
	
	<div>
		
		<el-dialog :title="titleWindows" :visible.sync="openWindows"  width="900px" append-to-body>
			
			<div>
				<el-link  class="time_panel" :class="dateSelTime.date==item.date?'primary':''"  @click="getDateDoctor(item)" v-for="(item ,index) in dateList" :key="index" >
					<div    class="time_panel_week">{{item.week}}</div>
					<div   class="time_panel_date">{{item.date}}</div>
				</el-link>
			</div>
			<div> 
			
			<el-table
				:v-loading="loading" :data="dataList" style="width: 100%">
				  
				  <el-table-column  label="医生" prop="name" width="150" />
				  
				  <el-table-column  label="医院" prop="patient_name" width="120" >
					  <template slot-scope="scope">					
						<div>{{getDoctorTitle(scope.row.doctorTitle)}}</div>
						<div >{{scope.row.hospitalName}}</div>
					  </template>
				  </el-table-column>
				  <el-table-column  label="擅长" prop="field"  />
				  
				  <el-table-column  label="操作" prop="patient_name" width="120" >
				  		<template slot-scope="scope">					
							 <el-button type="primary"  @click="selDoctorTime(scope.row)"  size="mini">预约</el-button>
				  		</template>
				  </el-table-column>
			</el-table>
			
				<!-- <uni-table :loading="loading" border stripe type="selection" :emptyText="$t('common.empty')" @selection-change="selectionChange">
					<uni-tr>
						<uni-th width="80" align="center">医生</uni-th>
						<uni-th width="120" align="center">医院</uni-th>
						<uni-th  align="center">擅长</uni-th> 
						<uni-th width="120" align="center">操作</uni-th>
					</uni-tr>
					<uni-tr v-for="(item ,index) in dataList" :key="index">
						<uni-td> <div class="name">{{item.name}}</div> </uni-td>
						<uni-td width="140">
							<div class="name">{{getDoctorTitle(item.doctorTitle)}}</div>
							<div class="name">{{item.hospitalName}}</div>
						</uni-td>
						<uni-td>
							<div class="name">{{item.field}}</div>
						</uni-td>
						<uni-td>
							<button class="uni-button" type="default" size="mini"  @click="selDoctorTime(item)">预约</button>
						</uni-td>
					</uni-tr>
				 </uni-table> -->
			</div>
		</el-dialog>
		
	
	</div>
</template>

<script>
	import timeObj  from '@/common/time.js'
	import config from '@/common/config.js'
	
	export default {
		name: "timePanel",
		props: {
		  titleWindows:{type:String,default:''},
		  hospitalId:{type:String,default:''},
		   type:{type:String,default:'consultment'},
		  isWindows: {type: Boolean,default: false,},
		},
		watch: {
		  isWindows: {
		    handler(val) { this.openWindows=val;},
		    immediate: true,
		  },
		  hospitalId: {
		    handler(val) { 
				this.pageCurrent=1;
				this.getDoctorList();
				
			},
		    immediate: true,
		  },
		  openWindows: {
		    handler(val) { this.openWindows=val;this.$emit("openWindows",val);},
		    immediate: true,
		  },
		},
		data() {
			return {
				loading:false,
				openWindows:true,
				doctorTitle:this.DoctorTitle,
				total:0,
				dataList:[],
				pageCurrent:1,
				dateList:timeObj.get7Days(),
				dateSelTime:timeObj.get7Days()[0],
				doctorList:[],
			}
			
		},
		created() {
		 
		},
		
		methods: {
			selDoctorTime(item){
			
				
				this.$emit("selDoctor",{data:item,date:this.dateSelTime.dateY});
				this.openWindows=false;
				if(this.type=='transfer'){
					
					this.$router.push('/addTransferHospital?doctorId='+item.id+"&date="+this.dateSelTime.dateY)
					// uni.redirectTo({
					// 	url:'/pages/appointment/addTransferHospital/addTransferHospital?doctorId='+item.id+"&date="+this.dateSelTime.dateY
					// })
					
				}
				
					
				
			},
			getDateDoctor(item){
				this.dateSelTime=item;
				this.pageCurrent=1;
				this.getDoctorList()
			},
			getDoctorTitle(value){
				
				for (var i = 0; i < this.doctorTitle.length; i++) {
					var item=this.doctorTitle[i]
					
					if(i==value){
						return item.text;
					}
					
				}
				
				return "";
				
				
			},
			getDoctorList(){
				var  _this1=this;
					_this1.dataList=[];
				config.ajaxAw("/kongtangweishi/api/doctorOnlineTime/getDoctorTimeList",
				{
					doctorDistrictId:config.doctorDistrictId,
					
					hospitalId: this.hospitalId,
					date:this.dateSelTime.dateY,
					page:this.pageCurrent,
					isPage:true,
					consultation: true,
					isOnline: true
				},function(data){
					setTimeout(function(){
						_this1.dataList=data;
						_this1.loading = false;
					},1000)
					_this1.total = data.totalElements;
				});
				
				
			}
			
			
		}
	}
	
	
</script>

<style>
	
	.time_panel{margin: 10px 20px;text-align: center}
	.time_panel_week{font-size: 12px;color: #999;}
	.time_panel_date{font-size: 16px;}
	
	.time_panel.primary{color: #409EFF;}
	.time_panel.primary .time_panel_week{color: #409EFF;}
</style>