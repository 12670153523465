<template>
	<div v-if="dataList">
		
		
		
		 <el-dialog :title="titleWindows" :visible.sync="openWindows"  width="850px" append-to-body>
		   <el-table  :data="dataList">
			   
			   <el-table-column label="时间" align="center" prop="endTime" >
				   
				    <template slot-scope="scope">
						{{scope.row.startTime}}-{{scope.row.endTime}}
					</template>
				</el-table-column>
				   
			   <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
			     <template slot-scope="scope">
			       <el-button
			         size="mini"
			         type="text"
			         icon="el-icon-message-solid"
					 @click="reservation(scope.row)"
			       >预约</el-button>
			     </template>
			   </el-table-column>
			   
		   </el-table>

		   
		  </el-dialog>
		
		
	</div>
	
</template>

<script>
	
	export default {
		name: "timePanel",
		props: {
		  titleWindows:{type:String,default:'人员'},
		  date:{type:Object,default:null},
		  dataList:{type:Array,default:null},
		  doctorId:{type:String,default:""},
		  isWindows: {type: Boolean,default: false,},
		},
		watch: {
		  isWindows: {
		    handler(val) { this.openWindows=val;},
		    immediate: true,
		  },
		  openWindows: {
		    handler(val) { this.openWindows=val;this.$emit("openWindows",val);},
		    immediate: true,
		  },
		},
		data() {
			return {
				loading:'false',
				openWindows:true,
				
			}
		},
		created() {
		 
		},
		
		methods: {
			reservation(item){
				
				 this.$router.push("/addConsultment?doctorId="+
					this.doctorId+"&date="+this.date.dateY+"&startTime="+
					item.startTime+"&endTime"+item.endTime);
				
				// uni.navigateTo({
				// 	url:"/pages/appointment/addConsultment/addConsultment?doctorId="+
				// 	this.doctorId+"&date="+this.date.dateY+"&startTime="+
				// 	item.startTime+"&endTime"+item.endTime
				// })
				this.openWindows=false;
			},
			
			
		}
	}
	
</script>

<style>
</style>